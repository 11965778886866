<template>
  <div>
    <div class="page-header clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/header_contact.jpg')"
      >
      </parallax>
      <div class="container">
        <h2 class="title mt-5">Contact Us</h2>
      </div>
    </div>
    <div class="section">
      <div class="container">

        <div class="row pt-5">
          <div class="col-lg-4 text-left">
            <img
              src="img/discovery_about.jpg"
              class="rounded animated fadeInDown"
            />
            <h5 class="font-weight-bold align-middle mt-4">
              Cikarang Factory & Office <span style="color: green;"> -  DISCOVERY </span>
            </h5>
            Kawasan Industri Jababeka 1
            Jl.Jababeka XIIB Blok W26
            Cikarang-Bekasi, Jawa Barat Indonesia Kode Pos 17530
            Telp: +62-21 893 6733 (Hunting) <br><br>

            Phone : +62 - 21 8936733 (Hunting)<br>
            Fax   : +62 - 21 8936738<br>
            Email : agus.setiyono@tef.co.id<br>

            <iframe class="mt-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.906490011956!2d107.1315160110587!3d-6.276024361410569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69853afdf6d23b%3A0x311708ec82801a2b!2sPT%20Tokki%20Engineering%20and%20Fabrication%20(Discovery)!5e0!3m2!1sid!2sid!4v1721981675835!5m2!1sid!2sid" width="350" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
          <div class="col-lg-4 text-left">
            <img
              src="img/endeavor.jpg"
              class="rounded animated fadeInDown"
            />
            <h5 class="font-weight-bold align-middle mt-4">
              Cilegon Factory & Office <span style="color: green;"> -  ENDEAVOR </span>
            </h5>
            Kawasan Industri Krakatau 1,
            Jl. Australia I Kav. C1/2, Kel. Warnasari, Kec. Citangkil
            Kota Cilegon, Banten - Indonesia 42443 <br><br>
            
            Phone : +62 - 254 8317244<br>
            Fax   : +62 - 254 8317244<br>
            Email : sutriyono@tef.co.id<br>

            <iframe class="mt-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.9049275460898!2d106.01250701105559!3d-6.007791558989433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e418f7363b3ec29%3A0x66340e0b0cf215b2!2sPT.%20Tokki%20Engineering%20and%20Fabrication%20(Plant%20Endeavor%20-%20Cilegon)!5e0!3m2!1sid!2sid!4v1721978644001!5m2!1sid!2sid" width="350" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
          <div class="col-lg-4 text-left">
            <img
              src="img/simba.jpg"
              class="rounded animated fadeInDown"
            />
            <h5 class="font-weight-bold align-middle mt-4">
              Gresik Branch Office <span style="color: green;"> - <br> SIMBA </span>
            </h5>
            Green Garden, Blok A-4 No. 01 Jl. Dr. Wahidin Sudirohusodo Kel. Dahanrejo, Kec. Kebomas Gresik, Jawa Timur, Indonesia 61124 <br><br>
            
            Phone : +62 - 31 39935914<br>
            Fax   : +62 - 31 39935914<br>
            Email : sales@tef.co.id<br>
            <iframe class="mt-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1979.325135962771!2d112.60025259999999!3d-7.1663663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e77ff4d2274450d%3A0xfce64b5eb1ef5473!2sPT.%20Tokki%20Engineering%20and%20Fabrication%20(SIMBA%20OFFICE)!5e0!3m2!1sid!2sid!4v1721981907884!5m2!1sid!2sid" width="350" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
      </div>

    </div>

    <!-- CONTACT US -->
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h2 class="font-weight-bold align-middle">
              Talk to Our <span style="color: green;">Marketing Team</span>
            </h2>
            <h6 class="text-black text-left font-weight-bold mt-n4">
              Please fill in the form below. Our reliable team will send you a message as soon as possible and answer your extraordinary project needs.
            </h6>
            <label class="mt-3">Name <span style="color: red;">*</span></label><br>
            <fg-input
              class="has-success input-lg mb-1"
              placeholder="Name"
              required
              v-model="inquiryData.name"
              >
            </fg-input>
            <label class="mt-3">Company Name <span style="color: red;">*</span></label><br>
            <fg-input
              class="has-success input-lg mb-1"
              placeholder="Company Name"
              required
              v-model="inquiryData.company_name"
              >
            </fg-input>
            <label class="mt-3">Email <span style="color: red;">*</span></label><br>
            <fg-input
              class="has-success input-lg mb-1"
              placeholder="Email"
              required
              type="email"
              v-model="inquiryData.email"
              >
            </fg-input>
            <label class="mt-3">Phone Number <span style="color: red;">*</span></label><br>
            <fg-input
              class="has-success input-lg mb-1"
              placeholder="Phone Number"
              required
              v-model="inquiryData.phone_number"
              >
            </fg-input>
            <label class="mt-3">Message <span style="color: red;">*</span></label><br>
            <textarea style="border-radius: 10px; border: 1px solid #DCDCDC; color: #808080; font-size: 12px; " rows="5" cols="80" v-model="inquiryData.message"> </textarea>

            <n-button type="success" size="lg" class="float-right mr-3 font-weight-bold" @click="save()" :disabled="onLoading">
              <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
              <span v-else>
                  <span>Send </span>
              </span>
            </n-button>
          </div>
          <div class="col-lg-6">
            <img
              src="img/send_email.png"
              class="img-fluid rounded"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- MOTO TOKKI  -->
    <div class="page-header clear-filter" filter-color="purple">
      <div class="parallax">
        <div class="container" style="">
          <h1 class="text-white text-left pt-5 font-weight-bold text-center">START <span style="font-size: 20px; color: yellow;"> with TOKKI </span></h1>
          <div class="row pt-5">
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">Main office & factory  :</span></h6>
              Kawasan Industri Jababeka 1
              Jl.Jababeka XIIB Blok W26
              Cikarang-Bekasi, Jawa Barat Indonesia Kode Pos 17530
              Telp: +62-21 893 6733 (Hunting) <br><br>

              Phone : +62 - 21 8936733 (Hunting)<br>
              Fax   : +62 - 21 8936738<br>
              Email : agus.setiyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">2nd office & factory</span></h6>
              Kawasan Industri Krakatau 1,
              Jl. Australia I Kav. C1/2, Kel. Warnasari, Kec. Citangkil
              Kota Cilegon, Banten - Indonesia 42443 <br><br>
              
              Phone : +62 - 254 8317244<br>
              Fax   : +62 - 254 8317244<br>
              Email : sutriyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">3rd Branch office</span></h6>
              Green Garden, Blok A-4 No. 01 Jl. Dr. Wahidin Sudirohusodo Kel. Dahanrejo, Kec. Kebomas Gresik, Jawa Timur, Indonesia 61124 <br><br>
            
              Phone : +62 - 31 39935914<br>
              Fax   : +62 - 31 39935914<br>
              Email : sales@tef.co.id<br>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { FormGroupInput, Button, Modal } from '@/components';
import config from '@/configs/config';
import Api from '@/helpers/api';
import inquiry from '@/services/inquiry.service';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    [FormGroupInput.name]: FormGroupInput,
    [Button.name]: Button,
  },
  data () {
    return {
      onLoading: false,
      inquiryData: {}, 
    }
  },
  methods: {
      save(){
        let api = null;
        let context = this;
        let formData = new FormData();
        this.onLoading = true;

        if (this.inquiryData.name != undefined && this.inquiryData.company_name != undefined && this.inquiryData.email != undefined && this.inquiryData.phone_number != undefined && this.inquiryData.message != undefined ) {
          formData.append('name', this.inquiryData.name);
          formData.append('company_name', this.inquiryData.company_name);
          formData.append('email', this.inquiryData.email);
          formData.append('phone_number', this.inquiryData.phone_number);
          formData.append('message', this.inquiryData.message);
        }else{
          context.onLoading = false
          return alert('All fields must be filled in')
        }

        api = Api(context, inquiry.create(formData));
        
        api.onSuccess(function(response) {
            context.$notify('Data sent successfully', 'success')
        }).onError(function(error) {                    
            context.$notify('Data failed to send', 'success')
        }).onFinish(function() {  
            context.onLoading = false
        })
        .call();
      },
    }

};
</script>
<style>
   .animated {
     
      padding-top:0px;
      margin-bottom:0px;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
   }
   
   @-webkit-keyframes fadeInDown {
      0% {
         opacity: 0;
         -webkit-transform: translateY(-20px);
      }
      100% {
         opacity: 1;
         -webkit-transform: translateY(0);
      }
   }
   
   @keyframes fadeInDown {
      0% {
         opacity: 0;
         transform: translateY(-50px);
      }
      100% {
         opacity: 1;
         transform: translateY(0);
      }
   }
   
   .fadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
   }
</style>
