<template>
  <navbar
    position="fixed"
    type="success"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="img/logo-tef.png" width="130" alt="" />
        <p class="ml-3"></p>
      </router-link>
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Designed by Invision. Coded by Creative Tim
        </div>
      </el-popover> -->
    </template>
    <template slot="navbar-menu">
      <li class="nav-item mr-n3">
        <router-link v-popover:popover1 class="navbar-brand" to="/">
         <i class="now-ui-icons shopping_shop mr-1"></i>  <p>Home</p>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link v-popover:popover1 class="navbar-brand" to="/about-us">
          <p><i class="now-ui-icons business_badge mr-1"></i> <span >About Us</span></p>
        </router-link>
      </li> -->
      <drop-down
        tag="li"
        title="About Us"
        icon="now-ui-icons mr-1 business_badge"
        class="nav-item"
      >
        <nav-link to="/about-us">
          About Us
        </nav-link>
        <nav-link to="/certification">
          Certification
        </nav-link>
        <nav-link to="/facilities">
          Facilities
        </nav-link>
      </drop-down>
      <li class="nav-item">
        <router-link v-popover:popover1 class="navbar-brand" to="/product">
          <p><i class="now-ui-icons ui-2_settings-90 mr-1"></i> <span >Product / Services</span></p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link v-popover:popover1 class="navbar-brand" to="/blog">
          <p><i class="now-ui-icons education_agenda-bookmark mr-1"></i> <span >News </span></p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link v-popover:popover1 class="navbar-brand" to="/career">
          <p><i class="now-ui-icons business_briefcase-24 mr-1"></i> <span >Career </span></p>
        </router-link>
      </li>
      <li class="nav-item">
        <!-- <router-link v-popover:popover1 class="navbar-brand" to="/login">
          <p><i class="now-ui-icons users_circle-08 mr-1"></i> <span >Login</span></p>
        </router-link> -->
       <!--  <div class="form-inline">
          <n-button type="warning" size="sm" class="float-right mr-3" @click="logout()"> Logout</n-button>
        </div> -->
      </li>
      <!-- <drop-down
        tag="li"
        title="Components"
        icon="now-ui-icons design_app"
        class="nav-item"
      >
        <nav-link to="/">
          <i class="now-ui-icons business_chart-pie-36"></i> All components
        </nav-link>
        <a
          href="https://demos.creative-tim.com/vue-now-ui-kit/documentation"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons design_bullet-list-67"></i> Documentation
        </a>
      </drop-down> -->
      <!-- <drop-down
              tag="li"
              title="Examples"
              icon="now-ui-icons design_image"
              class="nav-item"
      >
        <nav-link to="/landing">
          <i class="now-ui-icons education_paper"></i> Landing
        </nav-link>
        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08"></i> Login
        </nav-link>
        <nav-link to="/profile">
          <i class="now-ui-icons users_single-02"></i> Profile
        </nav-link>
      </drop-down> -->
      <li class="nav-item">
        <router-link v-popover:popover1 class="nav-link btn btn-neutral" to="/contact-us">
          <p><i class="now-ui-icons tech_mobile mr-1"></i> <span >Contact Us</span></p>
        </router-link>
      </li>

      <!-- <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href="https://twitter.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/CreativeTimOfficial"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li> -->
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink, Button } from '@/components';
import { Popover } from 'element-ui';
import config from '@/configs/config';
import axios from 'axios';
import Api from '@/helpers/api';
import akun from '@/services/akun.service';
import bus from '@/eventBus';

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover,
    [Button.name]: Button,
  },
  data() {
    return {
      role: '',
      authenticated: '',
    };
  },
  created () {
      var context = this;
      bus.$on('RELOAD_USER', function () {
        context.get();
        context.$forceUpdate();
      });
  },
  mounted() {
    // this.getRole();
    this.get();
  },
  methods: {
    get(){
      this.authenticated = localStorage.getItem('authenticated');
      this.$forceUpdate();
    },
    logout(){
      axios.get(config.apiUrl +"auth/logout").then(response => {
        localStorage.removeItem('token');
        localStorage.setItem('authenticated', false)
        this.$notify('Anda berhasil logout', 'success')
        this.$router.push('/login')
        this.$forceUpdate();
        this.get();
      }) 
      .catch(err => {
          this.$notify('Gagal Logout', 'error')
      })
    },
    getRole(){
      let context = this;     
      context.onLoading = true;          
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role = response.data.data[0].role;
      }).onError(function(error) {  
      })
      .call() 
      context.$forceUpdate();
    },
  }
};
</script>

<style scoped></style>
