<template>
  <div>
    <div class="page-header clear-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/grinding.png')"
      >
      </parallax>
      <div class="container">
        <h2 class="title mt-5">News</h2>
      </div>
    </div>
    <div class="section">
      <div class="container text-center">
         <img
            src="img/empty.png"
            class="rounded animated fadeInDown"
          />
          <p><strong> Content is empty </strong></p>
        <!-- <div class="cards animated fadeInDown">
          <div class="container">
            <div class="row">
              <div class="col-lg-9">
                <router-link to="/detail-blog">
                  <h4 class="font-weight-bold text-dark" style="cursor: pointer;">Metode pengelasan dan peralatan las pelat titanium dan batang titanium</h4>
                </router-link>
                <h5>KarenaPlat titanium kelas 5, Batang titanium kelas 5, tabung titanium memiliki sifat fisik dan kimia khusus, sehingga proses pengelasannya sangat berbeda dengan logam lainnya. Pengelasan titanium adalah proses pengelasan TiG ...</h5>
                <p class="mt-5">By Admin . June 18, 2024</p>
              </div>
              <div class="col-lg-3" style="cursor: pointer;">
                <img
                  src="img/image_blog/Welding.jpg"
                  class="rounded"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div><hr> -->
        <!-- <div class="">
          <div class="container">
            <div class="row">
              <div class="col-lg-9">
                <h4 class="font-weight-bold" style="cursor: pointer;">Titanium dengan Kekuatan dan Kelebihannya, Dipakai di Mana Saja?</h4>
                <h5>Titanium adalah logam yang sangat terkenal dan banyak digunakan sebagai perhiasan. Dinamakan berdasarkan dewa mitos Yunani, Titan, elemen yang menempati kolom ke-22 pada tabel periodik ini digunakan pada pesawat terbang dan tongkat lacrosse, peralatan medis dan tindik, sampai tabir surya....</h5>
                <p class="mt-5">By Admin . June 18, 2024</p>
                
              </div>
              <div class="col-lg-3" style="cursor: pointer;">
                <img
                  src="img/image_blog/titanium.jpg"
                  class="rounded"
                  width="1000"
                />
              </div>
            </div>
          </div>
        </div><hr>
        <div class="">
          <div class="container">
            <div class="row">
              <div class="col-lg-9">
                <h4 class="font-weight-bold" style="cursor: pointer;">Apa Itu Pipa Seamless?</h4>
                <h5>Pipa seamless adalah salah satu jenis pipa baja yang memiliki karakteristik unik, yaitu tanpa sambungan las pada permukaannya. Hal ini berbeda dengan pipa konvensional yang biasanya terdiri dari beberapa bagian yang disambung dengan menggunakan proses pengelasan. ...</h5>
                <p class="mt-5">By Admin . June 18, 2024</p>
                
              </div>
              <div class="col-lg-3" style="cursor: pointer;">
                <img
                  src="img/image_blog/seamles.jpeg"
                  class="rounded"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- MOTO TOKKI  -->
    <div class="page-header clear-filter" filter-color="purple">
      <div class="parallax">
        <div class="container" style="">
          <h1 class="text-white text-left pt-5 font-weight-bold text-center">START <span style="font-size: 20px; color: yellow;"> with TOKKI </span></h1>
          <div class="row pt-5">
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">Main office & factory  :</span></h6>
              Kawasan Industri Jababeka 1
              Jl.Jababeka XIIB Blok W26
              Cikarang-Bekasi, Jawa Barat Indonesia Kode Pos 17530
              Telp: +62-21 893 6733 (Hunting) <br><br>

              Phone : +62 - 21 8936733 (Hunting)<br>
              Fax   : +62 - 21 8936738<br>
              Email : agus.setiyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">2nd office & factory</span></h6>
              Kawasan Industri Krakatau 1,
              Jl. Australia I Kav. C1/2, Kel. Warnasari, Kec. Citangkil
              Kota Cilegon, Banten - Indonesia 42443 <br><br>
              
              Phone : +62 - 254 8317244<br>
              Fax   : +62 - 254 8317244<br>
              Email : sutriyono@tef.co.id<br>
            </div>
            <div class="col-lg-4 text-left">
              <h6 class="text-white text-left font-weight-bold"><span style="color: #7FFF00;">3rd Branch office</span></h6>
              Green Garden, Blok A-4 No. 01 Jl. Dr. Wahidin Sudirohusodo Kel. Dahanrejo, Kec. Kebomas Gresik, Jawa Timur, Indonesia 61124 <br><br>
            
              Phone : +62 - 31 39935914<br>
              Fax   : +62 - 31 39935914<br>
              Email : sales@tef.co.id<br>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  methods: {
    toDetailProduct(param){
      this.$router.push('/detail-product/'+param)
    }
  }
};
</script>
<style>
   .animated {
     
      padding-top:0px;
      margin-bottom:0px;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
   }
   
   @-webkit-keyframes fadeInDown {
      0% {
         opacity: 0;
         -webkit-transform: translateY(-20px);
      }
      100% {
         opacity: 1;
         -webkit-transform: translateY(0);
      }
   }
   
   @keyframes fadeInDown {
      0% {
         opacity: 0;
         transform: translateY(-50px);
      }
      100% {
         opacity: 1;
         transform: translateY(0);
      }
   }
   
   .fadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
   }


  .cards:hover{
    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(1.1);
    cursor: pointer;;
  }
</style>
